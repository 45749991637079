function configureInitAppVar() {

  /**
	 * b. build navigation
	 **/
	initApp.buildNavigation(myapp_config.navHooks);

  /**
  * c. initialize nav filter
  **/
 initApp.listFilter(myapp_config.navHooks, myapp_config.navFilterInput, myapp_config.navAnchor);
 console.log(initApp);

}

function configureMyAppVar() {
  myapp_config.root_= $('body');// used for core app reference
  myapp_config.root_logo = $('.page-sidebar > .page-logo'); // used for core app reference
	/*
	DELAY VAR FOR FIRING REPEATED EVENTS (eg., scroll & resize events)
	Lowering the variable makes faster response time but taxing on the CPU
	Reference: http://benalman.com/code/projects/jquery-throttle-debounce/examples/throttle/
	*/
	myapp_config.throttleDelay = 450; // for window.scrolling & window.resizing
	myapp_config.filterDelay = 150;   // for keyup.functions
	/*
	DETECT MOBILE DEVICES
	Description: Detects mobile device - if any of the listed device is
	detected a class is inserted to $.root_ and the variable thisDevice
	is decleard. (so far this is covering most hand held devices)
	*/
	myapp_config.thisDevice = null; // desktop or mobile
	myapp_config.isMobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase())); //popular device types available on the market
	myapp_config.mobileMenuTrigger = null; // used by pagescrolling and appHeight script, do not change!
	myapp_config.mobileResolutionTrigger = 992; //the resolution when the mobile activation fires
	/*
	DETECT IF WEBKIT
	Description: this variable is used to fire the custom scroll plugin.
	If it is a non-webkit it will fire the plugin.
	*/
	myapp_config.isWebkit = ((!!window.chrome && !!window.chrome.webstore) === true || Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 === true);
	/*
	DETECT CHROME
	Description: this variable is used to fire the custom CSS hacks
	*/
	myapp_config.isChrome = (/chrom(e|ium)/.test(navigator.userAgent.toLowerCase()));
	/*
	DETECT IE (it only detects the newer versions of IE)
	Description: this variable is used to fire the custom CSS hacks
	*/
	myapp_config.isIE = ( (window.navigator.userAgent.indexOf('Trident/') ) > 0 === true );
	/*
	DEBUGGING MODE
	debugState = true; will spit all debuging message inside browser console.
	*/
	myapp_config.debugState = true; // outputs debug information on browser console
	/*
	Turn on ripple effect for buttons and touch events
	Dependency:
	*/
	myapp_config.rippleEffect = true; // material design effect that appears on all buttons
	/*
	Primary theme anchor point ID
	This anchor is created dynamically and CSS is loaded as an override theme
	*/
	myapp_config.mythemeAnchor = '#mytheme';
	/*
	Activate last tab
	Stores the last tab in localstorage and activates it
	*/
	myapp_config.activateLastTab = true;
	/*
	Primary menu anchor point #js-primary-nav
	This is the root anchor point where the menu script will begin its build
	*/
	myapp_config.navAnchor = $('#js-primary-nav'); //changing this may implicate slimscroll plugin target
	myapp_config.navHooks = $('#js-nav-menu'); //changing this may implicate CSS targets
	myapp_config.navAccordion = true; //nav item when one is expanded the other closes
	myapp_config.navInitalized= 'js-nav-built'; //nav finished class
	myapp_config.navFilterInput = $('#nav_filter_input'); //changing this may implicate CSS targets
	myapp_config.navHorizontalWrapperId= 'js-nav-menu-wrapper';
	/*
	The rate at which the menu expands revealing child elements on click
	Lower rate reels faster expansion of nav childs
	*/
	myapp_config.navSpeed = 500; //ms
	/*
	Color profile reference hook (needed for getting CSS value for theme colors in charts and various graphs)
	*/
	myapp_config.mythemeColorProfileID = $('#js-color-profile');
	/*
	Nav close and open signs
	This uses the fontawesome css class
	*/
	myapp_config.navClosedSign = 'fal fa-angle-down';
	myapp_config.navOpenedSign = 'fal fa-angle-up';
	/*
	Application icon prefix for error messages etc
	other options are fad, fal, fas, far, ni
	*/
	myapp_config.appIconPrefix = 'fal';
	/*
	App date ID
	found inside the breadcrumb unit, displays current date to the app on pageload
	*/
	myapp_config.appDateHook = $('.js-get-date');
	/*
	* SaveSettings to localStorage
	* DOC: to store settings to a DB instead of LocalStorage see below:
	*    initApp.pushSettings("className1 className2") //sets value
	*    var DB_string = initApp.getSettings(); //returns setting string
	*/
	myapp_config.storeLocally = true;
	/*
	* Used with initApp.loadScripts
	* DOC: Please leave it blank
	*/
	myapp_config.jsArray = [];
}

function loadScript() {
  configureMyAppVar();
  configureInitAppVar();
}

